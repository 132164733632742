import React from "react";
import Home from '../home/Home';
import Typography from '@material-ui/core/Typography';
import { Route, Switch} from 'react-router-dom';


const NotFound = () => <Typography variant="h3">404 - Not Found</Typography>;

//main application is routed here
export default function AppRouter() {
    

    return (
        <div className="appRouter">
            <Switch>
                <Route exact path="/*" component={Home} />
                <Route component={NotFound} />
            </Switch>
        </div>
    );
}
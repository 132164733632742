import React from 'react';
import PropTypes from 'prop-types';
import tiLogo from '../../assets/ti-logo.svg';
import NavMenu from '../nav-menu/NavMenu';
import { Drawer, Divider, ListItem } from '@material-ui/core';

export default function NavDrawer(props) {
    const { classes, open, toggle } = props;

    //container for the menu in the app bar
    return (
        <Drawer className={classes.drawer}
            variant="persistent"
            open={open}
            onClose={toggle}
            classes={{
                paper: classes.drawerPaper
            }}
        >
            <div className={classes.toolbar}/>
            <NavMenu />
            <div className={classes.footer}>
                <Divider />
                <ListItem >
                    <img src={tiLogo} alt="Texas Instruments Inc." />
                </ListItem> 
            </div>
        </Drawer>
    )
}

NavDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    toggle: PropTypes.func
}
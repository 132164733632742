import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    toolbar:{
        height: "60px"
    },
    title: {
        marginLeft: 25
    },
});

//main headers for each page. accepts title and color
const PageHeader = props => {
    const classes = useStyles();
    const { color, title } = props;
    return (
        <div className={classes.root}>
            <AppBar position="static" color={color}>
                <Toolbar className={classes.toolbar}>
                    <Typography className={classes.title} variant="h4" color="inherit">{title}</Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}

PageHeader.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string
}

export default PageHeader;
import React from 'react';
import AppRouter from '../../routes/AppRouter';
import NavDrawer from '../nav-drawer/NavDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import { useToggle } from '../../hooks/common';
import useStyles from './styles';
import classNames from 'classnames';
import {
    AppBar, Toolbar, Typography, IconButton
} from '@material-ui/core';


const NavBar = () => {
    const classes = useStyles();
    const [open, toggle] = useToggle(false);

    //top menu bar of the application. app navigation is handled through the approuter and navmenu in the drawer. 
    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                   
                    <Typography variant="h6" color="inherit">
                        Barcode Generator
                    </Typography>
                </Toolbar>
            </AppBar>
            <NavDrawer classes={classes} open={open} toggle={toggle} />
            <div className={classNames(classes.content, { [classes.contentShift]: open })}>
                <div className={classes.toolbar} />
                <AppRouter /> 
            </div>
        </div>
    )
}

export default NavBar;
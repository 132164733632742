import React from 'react';
import { withRouter } from 'react-router-dom';
import { List, ListSubheader, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    }
});

const NavMenu = props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <List subheader={<ListSubheader>Menu</ListSubheader>}>
            </List>
        </div>
    );
}

export default withRouter(NavMenu);
import React, { Component} from 'react';
import PageHeader from '../common/PageHeader';
import BarCodeForm from './BarCodeForm';
import document from '../assets/6512960.doc';

//todo remove nav drawer related files if its not required
class Home extends Component
{
  
render() {
    const center={
        display: 'flex', justifyContent:'center', alignItems:'center'
    }
    return (
        <React.Fragment>
        <div >
            <PageHeader title="Home" color="secondary" />
            <div style={{ backgroundColor: "#EEEEEE" }}>
        <div id="divs" style={center}>

          
          <h5  style={{ padding: "0px",margin: "10px"}}>
              
              <a href={document}>Texas Instruments Supplier Packing and Labeling Manual For Global Shipping</a><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NOTE: This Website does not work with Internet Explorer (IE)<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For any concerns, please contact stt@list.ti.com</h5>
          
          </div>
        </div>
            
            <div style={center}>
                <BarCodeForm/>
               
            </div>
        </div>
        </React.Fragment>
    );
}

}

export default Home;
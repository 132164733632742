import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import {  DialogActions } from '@material-ui/core';
import tiLogo from '../assets/ti-logo.svg';
import Barcode from 'react-barcode';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
export default class BarCodeForm extends React.Component {

    constructor(props) {
        super(props)
        const query = window.location.search;
        this.state = {
            deliveryNote: new URLSearchParams(query).get("DN"),
            fromAddrs: '',
            toAddrs: '',
            splitadd: null,
            poNumber: new URLSearchParams(query).get("po"),
            poLineItem: new URLSearchParams(query).get("LI"),
            tiPartNumber: '',
            quantity: new URLSearchParams(query).get("Q"),
            revisionLetter: null,
            description: new URLSearchParams(query).get("D"),
            vendorBatch:null,
            descriptionWarning: '',
            fromAddressWarning: '',
            warnings: '',
            toAddressWarning: '',
            packCount1: null,
            packCount2: null,
            warningDialogOpen: false,
            weight1: null,
            weight2: null,
            printMdate: null,
            printEDare:null,
            manufactureDate: null,
            expirationDate: null,
            addDialog: false,
            barCodeDialogOpen: false,
            deliveryNoteErr: null,
            quantityErr: null,
            poNumberErr: null,
            poLineItemErr: null,
            descriptionErr: null,
            packCount1Err: null,
            packCount2Err: null
        }

    }

    clearErr = () => {
        console.log("hit")
        this.setState({
            deliveryNoteErr: null,
            quantityErr: null,
            poNumberErr: null,
            descriptionWarning: '',
            fromAddressWarning: '',
            warnings: '',
            toAddressWarning: '',
            poLineItemErr: null,
            descriptionErr: null,
            packCount1Err: null,
            packCount2Err: null
        })
    }
    clearAll = () => {
        this.clearinput()
        this.clearErr()
    }
   
    clearinput = () => {
      
      //  console.log("this.state.deliveryNote")
        this.setState({
            deliveryNote: '',
            fromAddrs: '',
            toAddrs: '',
            splitadd: '',
            poNumber: '',
            poLineItem:'',
            tiPartNumber: '',
            quantity: '',
            revisionLetter: '',
            description: '',
            packCount1: '',
            packCount2: '',
            weight1: '',
            vendorBatch:'',
            weight2: '',
            printMdate: '',
            manufactureDate: null,
            expirationDate: null,
        })
        //console.log(this.state.deliveryNote)
    }

    toggleAddDialog = (flag) => {
        this.setState({ addDialog: flag })
    };
    /*printDiv = (divId) => {
        var printContent = document.getElementById(divId);
        var WinPrint = window.open('', '', 'width=900,height=650');
        WinPrint.document.write(printContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
    }*/
    printDiv = (divId) => {
        var contents = document.getElementById(divId).innerHTML;
        var frame1 = document.createElement('iframe');
        frame1.name = "frame1";
        frame1.style.position = "absolute";
        frame1.style.top = "-1000000px";
        document.body.appendChild(frame1);
        var frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
        frameDoc.document.open();

        frameDoc.document.write('<html><head>');
        frameDoc.document.write('</head><body >');
        frameDoc.document.write(contents);
        frameDoc.document.write('</body></html>');
        frameDoc.document.close();
        setTimeout(function () {
            window.frames["frame1"].focus();
            window.frames["frame1"].print();
            document.body.removeChild(frame1);
        }, 500);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleDateChange = (date) => {
        this.setState({ manufactureDate: this.setDate(date) });
    };
    //function to format the date as required
    setDate = (date) => {
        let month = '' + (date.getMonth() + 1)
        let day = '' + date.getDate()
        let year = date.getFullYear()

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        let finalDate = year + '/' + month + '/' + day
        return finalDate
    }
    handleExpDateChange = (date) => {
        this.setState({ expirationDate: this.setDate(date) });
    }

    //valdiates description - ignore if >3 lines , each line to be <35 char
    valdiateDescription = (SplitDescription) => {
        let containsWarning = false
        var ThreeLineDescription = ''
        var arr = []
        let sizeMsg = null
        for (let i = 0; i < SplitDescription.length; i++) {
            
            if (i > 2) {
                //console.log("more than 2")
                sizeMsg = "More than three lines were entered in the description.  Extra lines will be ignored.\n"
                containsWarning = true
                break
            }
            
            if (SplitDescription[i].length > 35) {
                containsWarning = true
                arr.push(i + 1)
            }

           
            if (i=== SplitDescription.length - 1) {
                ThreeLineDescription = ThreeLineDescription + SplitDescription[i].substr(0, 35);
            }
            else {
                ThreeLineDescription = ThreeLineDescription + SplitDescription[i].substr(0, 35) + "\n";
            }

        }
        if (arr.length > 0) {

            if (sizeMsg !== null) {

                let valuee = sizeMsg + " Lines: " + arr.toString() + " in the DESCRIPTION is greater than 35 characters.\n"
                this.setState({ descriptionWarning: valuee })
               // console.log(this.state.descriptionWarning)

            }
            else {

                this.setState({ descriptionWarning: "Lines " + arr.values() + " in the DESCRIPTION is greater than 35 characters.\n" })
               //console.log(this.state.descriptionWarning)
            }
        }
        else {
            this.setState({ descriptionWarning: sizeMsg })
        }
        this.setState({ description: ThreeLineDescription })
        //console.log(this.state.descriptionWarning)
        return containsWarning
    }
    //validate - ignore if >4 lines , each line less than 25 chars
    validateFromAddress = (SplitFromAddress) => {

        // Take the From Address and split it into a maximum of four lines
        let containsWarning = false
        let FourLineFromAddress = ''
        let arr = []
        let sizeMsg = null
        for (let i = 0; i < SplitFromAddress.length; i++) {
            if (i > 3) {
                containsWarning = true
                //console.log("more than 4")
                sizeMsg = "More than four lines were entered in the from address.  Extra lines will be ignored.\n"
                console.log(sizeMsg)
                break
            }

            if (SplitFromAddress[i].length > 25) {
                containsWarning = true
                arr.push(i + 1)
            }

            if (i === SplitFromAddress.length - 1) {
                FourLineFromAddress = FourLineFromAddress + SplitFromAddress[i].substr(0, 25);
            }
            else {
                FourLineFromAddress = FourLineFromAddress + SplitFromAddress[i].substr(0, 25) + "\n";
            }
        }
        if (arr.length > 0) {
            if (sizeMsg !== null) {
                let valuee = this.state.fromAddressWarning + "Lines " + arr.toString() + "  in the FROM address is greater than 25 characters.  \n"
                this.setState({ fromAddressWarning: valuee })
               // console.log(this.state.fromAddressWarning)
            }
            else {
                let valuee = "Lines " + arr.values() + "  in the FROM address is greater than 25 characters.\n"
                this.setState({ fromAddressWarning: valuee })
               // console.log(this.state.fromAddressWarning)
            }
        }
        else {
            this.setState({ fromAddressWarning: sizeMsg })
        }
        this.setState({ fromAddrs: FourLineFromAddress })
      // console.log(this.state.descriptionWarning)
        return containsWarning
    }
    //validate to address 4 lines and each line <=25 chars
    validateToAddress = (SplitToAddress) => {
        let containsWarning = false
        let FourLineToAddress = ''
        let arr = []
        let sizeMsg = null
        
        for (let i = 0; i < SplitToAddress.length; i++) {
           
         if (i > 3) {
                //console.log("more than 4")
                containsWarning = true
                sizeMsg = "More than four lines were entered in the To address.  Extra lines will be ignored.\n"
                break

            }

            if (SplitToAddress[i].length > 25) {
                containsWarning = true
                arr.push(i + 1)
            }

            
            if (i === SplitToAddress.length - 1) {
                FourLineToAddress = FourLineToAddress + SplitToAddress[i].substr(0, 25);
            }
            else {
                FourLineToAddress = FourLineToAddress + SplitToAddress[i].substr(0, 25) + "\n"
            }
        }
        if (arr.length > 0) {
            if (sizeMsg !== null) {
                let valuee = this.state.toAddressWarning + "Lines " + arr.toString() + "  in the TO address is greater than 25 characters.\n"
                this.setState({ toAddressWarning: valuee })
              //  console.log(this.state.toAddressWarning)

            }
            else {
                let valuee = "Lines " + arr.values() + "  in the TO address is greater than 25 characters. \n"
                this.setState({ toAddressWarning: valuee })
                //console.log(this.state.toAddressWarning)
            }
        }
        else {
            this.setState({ toAddressWarning: sizeMsg })
        }
        this.setState({ toAddrs: FourLineToAddress })
        return containsWarning
    }
    //checks if warnings exist for fields other than description, from and to address
    validateWarnings = () => {
        let containsWarnings = false
        let msg = ""
        var SplitPN = this.state.tiPartNumber.split("-");
        if (this.state.poNumber.length !== 10) {
            containsWarnings = true
            msg = "The PO number you entered is not 10 digits long.  Most TI POs are 10 digits in length. \n"

        }
        if (this.state.poLineItem % 10 !== 0) {
            containsWarnings = true
            msg = msg + "The item number you entered is not evenly divisible by 10.  Many (but not all) TI item numbers are multiples of 10. \n"

        }
        if (this.state.tiPartNumber && (isNaN(SplitPN[0]) || isNaN(SplitPN[1]) || SplitPN[0].length !== 7 || SplitPN[1].length !== 4 || this.state.tiPartNumber > 12)) {
            containsWarnings = true
            msg = msg + "Many (but not all) TI part numbers follow a 7-4 format (NNNNNNN-NNNN) and must be padded with leading zeros (e.g. 0849333-0001). \n"

        }
        this.setState({ warnings: msg })
        return containsWarnings
    }
    checkIfWarningsExists = () => {
        let isWarningsExists = false
        var SplitDescription = this.state.description.split(/\n/)
        var SplitFromAddress = this.state.fromAddrs.split(/\n/);
        var SplitToAddress = this.state.toAddrs.split(/\n/);
        if (this.valdiateDescription(SplitDescription)) {
            isWarningsExists = true
        }
        if (this.validateFromAddress(SplitFromAddress)) {
            isWarningsExists = true
        }
        if (this.validateToAddress(SplitToAddress)) {
            isWarningsExists = true
        }
        if (this.validateWarnings()) {
            isWarningsExists = true
        }
        return isWarningsExists
    }
    validateBarcode = () => {
        this.clearErr()
        //if no errors check for warnings, if warnings exist pop a dialog to display it 
        if (!(this.validateField())) {
            
            if(this.state.expirationDate)
            {
                this.setState({ printEDate: this.state.expirationDate.toString().split("/").join("") })
            }
            if(this.state.manufactureDate)
            {this.setState({printMDate: this.state.manufactureDate.toString().split("/").join("") })
        }
            if (this.checkIfWarningsExists()) {
        
                this.toggleWarningDialog(true)
            }
            else {
                this.toggleBarCodeGenerationDialog(true)
            }

        }
        else{
            alert("There are errors existing in the form,please correct the errors and proceed")
        }

    }
    //validate all possible errors
    validateField = () => {
        let containsError = false
        if (this.state.deliveryNote === null || (!this.state.deliveryNote.trim())) { //sep function for frontend validation
            containsError = true
            this.setState({ deliveryNoteErr: "Please enter a delivery note" })

        }
        if (this.state.quantity === null || (!this.state.quantity.trim()) || this.state.quantity < 1 || isNaN(this.state.quantity)) {
            this.setState({ quantityErr: "Please enter a valid quantity (non-blank, numeric and greater than 0)." });
            containsError = true
        }
        if (this.state.poNumber === null || (!this.state.poNumber.trim()) || isNaN(this.state.poNumber)) {
            this.setState({ poNumberErr: "Please enter a PO number and it must be numeric." });
            containsError = true
        }
        if ((this.state.poLineItem === null || (!this.state.poLineItem.trim()))
            && (this.state.tiPartNumber === null || (!this.state.tiPartNumber.trim()))) {
            this.setState({ poLineItemErr: "Please enter PO line item number(5 or lesser digits ) or TI part number" });
            containsError = true;
        }
        if ((this.state.description === null || (!this.state.description.trim()))) {
            this.setState({ descriptionErr: "You must enter a description for the item." });
            containsError = true;
        }
        if ((this.state.packCount1 !== null && this.state.packCount1 <=0)) {
            containsError = true;
            this.setState({ packCount1Err: "Count should be >0" });
        }
        if((this.state.packCount1>this.state.packCount2))
        {
            containsError = true;
            this.setState({ packCount1Err: "count1 to be < count 2" });
        }
        if ((this.state.packCount2 !== null && this.state.packCount2 <= 0)) {
            containsError = true;
            this.setState({ packCount2Err: "Count should be >0" });
        }
        return containsError
    }
    toggleWarningDialog = (flag) => {

        this.setState({ warningDialogOpen: flag });
    };
    warningDialogComponent = () => {
        //console.log(this.state.warnings)
        //console.log(this.state.fromAddressWarning)
        //console.log(this.state.toAddressWarning)
       // console.log(this.state.descriptionWarning)

        var SplitWarnings = []
        var from = [] 
        var to = []
        var desc = []
        if (this.state.warnings) { SplitWarnings = this.state.warnings.split(/\n/); }
        if (this.state.fromAddressWarning) { from = this.state.fromAddressWarning.split(/\n/) }
        if (this.state.toAddressWarning) { to = this.state.toAddressWarning.split(/\n/) }
        if (this.state.descriptionWarning) { desc = this.state.descriptionWarning.split(/\n/) }

        return (
            <Dialog
                open={this.state.warningDialogOpen}
                onClose={() => this.toggleWarningDialog(false)}
                fullWidth={true}
                maxWidth={"lg"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-slide-title">The following warnings exists please click cancel to edit or click generate to continue</DialogTitle>
                <DialogContent>
                <ul>
                    {SplitWarnings.map(element =>
                       element!==''? <li>{element}</li>:null
                    )}
                    {desc.map(element =>
                        element!==''? <li>{element}</li>:null

                    )}
                    {from.length > 0 ? from.map(element =>
                            element!==''? <li>{element}</li>:null
                           


                    ) : ""}
                    {to.length > 0 ? to.map(element =>

                    element!==''? <li>{element}</li>:null


                    ) : ""}

                </ul>   


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.toggleWarningDialog(false)} >
                        Cancel
                    </Button>
                    <Button size="large" onClick={() => this.toggleBarCodeGenerationDialog(true)} align="center" style={{ margin: '20px', textAlign: 'center' }} variant="contained" color="primary">
                        Generate </Button>

                </DialogActions>
            </Dialog>
        )
    }
    toggleBarCodeGenerationDialog = (flag) => {
        this.setState({ barCodeDialogOpen: flag });
    };
    BarCodeGenerationComponent = () => {
        var from = []
        var to = []
        var desc = []
        if (this.state.fromAddrs) { from = this.state.fromAddrs.split(/\n/) }
        if (this.state.toAddrs) { to = this.state.toAddrs.split(/\n/) }
        if (this.state.description) { desc = this.state.description.split(/\n/) }
        const trStyle = {
            paddingBotton: '10px'
        }

        const center = {
            display: 'flex', justifyContent: 'center', alignItems: 'center'
        }

        const pStyle = {
            fontSize: 12,
            color: "black",
            textAlign: "center",
            paddingTop: "0px",
            paddingBotton: "0px",
            margin: "0px"
        }
        const pNoCenter = {
            fontSize: 12,
            color: "black",
            paddingTop: "0px",
            paddingBotton: "0px",
            margin: "0px"
        }

        return (
            <Dialog
                open={this.state.barCodeDialogOpen}
                onClose={() => this.toggleBarCodeGenerationDialog(false)}
                fullWidth={true}
                maxWidth={"xs"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div>

                            <div id="print" align="center">

                                <form>
                                    <table width="60%" align="center" border="1px" style={{ borderCollapse: "Collapse" }}>
                                        <tr>
                                            <td border="1px"><p style={{ fontSize: 12, color: "black" }}>From :</p>
                                                {from.length > 0 ? from.map(element =>
                                                    <p style={{ fontSize: 12, color: "black", marginTop: "-10px" }}>{element}</p>
                                                ) : ""}

                                            </td>
                                            <td><p style={{ fontSize: 12, color: "black" }}>To : </p>
                                                {to.length > 0 ? to.map(element =>
                                                    <p style={{ fontSize: 12, color: "black", marginTop: "-10px" }}>{element}</p>
                                                ) : ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <p style={pStyle}>(14K) PO/Line</p>
                                                <div style={center}>
                                                    <Barcode
                                                        width="1.5px"
                                                        marginTop="0px"
                                                        height="25"
                                                        fontSize="12"
                                                        value={"14K" + this.state.poNumber + "+" + this.state.poLineItem} />
                                                </div>
                                            </td>
                                        </tr>
                                        {this.state.tiPartNumber ?
                                            <tr>
                                                <td colSpan="3">
                                                    <p style={pStyle}>(P) TI P/N</p>
                                                    <div style={center}>
                                                        <Barcode
                                                            width="1.5px"
                                                            marginTop="0px"
                                                            height="25"
                                                            fontSize="12"
                                                            value={"P" + this.state.tiPartNumber} />
                                                    </div>
                                                </td>
                                            </tr> : null}
                                        {this.state.revisionLetter ?
                                            <tr>
                                                <td colSpan="3">
                                                    <p style={pStyle}>(2P) Revision Letter</p>
                                                    <div style={center}>
                                                        <Barcode
                                                            width="1.5px"
                                                            marginTop="0px"
                                                            height="25"
                                                            fontSize="12"
                                                            value={"2P" + this.state.revisionLetter} />
                                                    </div>
                                                </td>
                                            </tr> : null}
                                        <tr>
                                            <td colSpan="3">
                                                <p style={pStyle}>(Q) Quantity</p>
                                                <div style={center}>
                                                    <Barcode
                                                        width="1.5px"
                                                        marginBottom="0px"
                                                        height="25"
                                                        fontSize="12"
                                                        value={"Q" + this.state.quantity} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <p style={pStyle}>(11K) Delivery Note</p>
                                                <div style={center}>
                                                    <Barcode
                                                        width="1.5px"
                                                        marginTop="0px"
                                                        height="25"
                                                        fontSize="12"
                                                        value={"11K" + this.state.deliveryNote} />
                                                </div>
                                            </td>
                                        </tr>
                                        {this.state.manufactureDate ?
                                            <tr>
                                                <td colSpan="3">
                                                    <p style={pStyle}>(16D) Manufacture Date</p>
                                                    <div style={center}>
                                                        <Barcode
                                                            width="1.5px"
                                                            marginTop="0px"
                                                            height="25"
                                                            fontSize="12"
                                                            value={"16D" + this.state.printMDate} />
                                                    </div>
                                                </td>


                                            </tr> : null}
                                        {this.state.expirationDate ?
                                            <tr>
                                                <td colSpan="3">
                                                    <p style={pStyle}>(14D) Expiration Date</p>
                                                    <div style={center}>
                                                        <Barcode
                                                            width="1.5px"
                                                            marginTop="0px"
                                                            height="25"
                                                            fontSize="12"
                                                            value={"14D" + this.state.printEDate}
                                                        />
                                                    </div>
                                                </td>
                                            </tr> : null}
                                            {this.state.vendorBatch ?
                                        <tr>
                                            <td colSpan="3">
                                                <p style={pStyle}>(1T) Vendor Batch</p>
                                                <div style={center}>
                                                    <Barcode width="1.5px"
                                                        marginTop="0px"
                                                        height="25"
                                                        fontSize="12"
                                                        value={"1T" + this.state.vendorBatch} />
                                                </div>
                                            </td>
                                        </tr>:null}
                                        <tr>
                                            <td colSpan="3">
                                                {desc.length > 0 ? desc.map(element =>
                                                    <p style={{ fontSize: 12, color: "black", marginTop: "0px", paddingTop: "0px", paddingBotton: "0px" }}>{element}</p>
                                                ) : ""}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                <p style={pNoCenter}>
                                                    Package Count: </p><p style={pNoCenter}>
                                                    {this.state.packCount1 && this.state.packCount2 ? this.state.packCount1  + " of " + this.state.packCount2 : null}
                                                </p> 
                                            </td>
                                            <td >
                                                <p style={pNoCenter}>
                                                    Weight: </p>
                                                    <p style={pNoCenter}>
                                                    {this.state.weight1 ? this.state.weight1 + " kg " : ''}{this.state.weight2? this.state.weight2 + " lbs" : ''}
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                    <div align="center">&copy;<img src={tiLogo} style={{ width: "30%", height: "18px" }} alt="Texas Instruments Inc." /></div>
                                </form>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.toggleBarCodeGenerationDialog(false)} >
                        Cancel
                    </Button>
                    <Button size="large" onClick={() => this.printDiv("print")} align="center" style={{ margin: '20px', textAlign: 'center' }} variant="contained" color="primary">
                        Print</Button>

                </DialogActions>
            </Dialog>
        )
    }
    render() {

        const trStyle = {
            paddingBotton: '10px'
        }

        const center = {
            display: 'flex', justifyContent: 'center', alignItems: 'center'
        }

        return (
            <React.Fragment>
                {this.BarCodeGenerationComponent()}
                {this.warningDialogComponent()}
                <React.Fragment>

                    <Card>
                        <form>
                            <table cellSpacing="20px" width="100%">
                                <tr style={trStyle}>
                                    <td>
                                        <h4>Delivery Note <span style={{ color: 'red' }}>*</span> : </h4>
                                    </td>
                                    <td>
                                        <TextField
                                            style={{ minWidth: '200px' }}
                                            
                                            value={this.state.deliveryNote}
                                            required={true}
                                            name='deliveryNote'
                                            onChange={this.handleChange}
                                            helperText={this.state.deliveryNoteErr}
                                            FormHelperTextProps={{ style: { color: "red" } }}
                                        />

                                    </td>
                                    <td>
                                        <h4>Quantity <span style={{ color: 'red' }}>*</span> : </h4>
                                    </td>
                                    <td>
                                        <FormControl style={{ minWidth: '200px' }} >
                                            <TextField
                                                name="quantity"
                                                type="number"
                                                value={this.state.quantity}
                                                onChange={this.handleChange}
                                                helperText={this.state.quantityErr}
                                                FormHelperTextProps={{ style: { color: "red" } }}
                                            />
                                        </FormControl>
                                    </td>
                                    <td>
                                        <h4>PO Number <span style={{ color: 'red' }}>*</span> : </h4>
                                    </td>
                                    <td>
                                        <FormControl style={{ minWidth: '200px' }} >
                                            <TextField
                                                name="poNumber"
                                                type="number"
                                                value={this.state.poNumber}
                                                onChange={this.handleChange}
                                                helperText={this.state.poNumberErr}
                                                FormHelperTextProps={{ style: { color: "red" } }}
                                            />
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr style={trStyle}>
                                    <td>
                                        <h4>PO Line Item <span style={{ color: 'red' }}>*</span> : </h4>
                                    </td>
                                    <td>
                                        <FormControl style={{ minWidth: '200px' }} >
                                            <TextField
                                                name="poLineItem"
                                                type="number"
                                                value={this.state.poLineItem}
                                                onChange={this.handleChange}
                                                helperText={this.state.poLineItemErr}
                                                FormHelperTextProps={{ style: { color: "red" } }}
                                            />
                                        </FormControl>
                                    </td>
                                    <td>
                                        <h4>TI Part Number : </h4>
                                    </td>
                                    <td>
                                        <FormControl style={{ minWidth: '200px' }} >
                                            <TextField
                                                name="tiPartNumber"
                                                value={this.state.tiPartNumber}
                                                onChange={this.handleChange} />
                                        </FormControl>
                                    </td>
                                    <td>
                                        <h4>Revision Letter : </h4>
                                    </td>
                                    <td>
                                        <FormControl style={{ minWidth: '200px' }} >
                                            <TextField
                                                name="revisionLetter"
                                                value={this.state.revisionLetter}
                                                onChange={this.handleChange}
                                            />
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr style={trStyle}>
                                    <td>
                                        <h4>Description <span style={{ color: 'red' }}>*</span> : </h4>
                                    </td>
                                    <td colSpan={5}>
                                        <FormControl fullWidth >
                                            <TextField
                                                name="description"
                                                multiline
                                                rows={3}
                                                value={this.state.description}
                                                onChange={this.handleChange}
                                                helperText={this.state.descriptionErr}
                                                FormHelperTextProps={{ style: { color: "red" } }}
                                            />
                                            <FormHelperText>Use 35 chars x 3 lines format</FormHelperText>
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr style={trStyle}>
                                    <td>
                                        <h4>Manufacture Date : </h4>
                                    </td>
                                    <td>
                                        <FormControl style={{ minWidth: '200px' }} >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    error={false}
                                                    helperText={null}
                                                    value={this.state.manufactureDate}
                                                    format="yyyyMMdd"
                                                    name="manufactureDate"
                                                    onChange={this.handleDateChange}
                                                />

                                            </MuiPickersUtilsProvider>
                                            <FormHelperText>Click to select the date</FormHelperText>

                                        </FormControl>
                                    </td>
                                    <td>
                                        <h4>Expiration Date : </h4>
                                    </td>
                                    <td>
                                        <FormControl style={{ minWidth: '200px' }} >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    error={false}
                                                    helperText={null}
                                                    value={this.state.expirationDate}
                                                    format="yyyyMMdd"
                                                    name="expirationDate"
                                                    onChange={this.handleExpDateChange}
                                                />

                                            </MuiPickersUtilsProvider>
                                            <FormHelperText>Click to select the date</FormHelperText>
                                        </FormControl>
                                    </td>
                                    <td>
                                        <h4>Vendor Batch : </h4>
                                    </td>
                                    <td>
                                        <FormControl style={{ minWidth: '200px' }} >
                                            <TextField
                                                name="vendorBatch"
                                                value={this.state.vendorBatch}
                                                onChange={this.handleChange}
                                            />

                                        </FormControl>
                                    </td>
                                </tr>
                                <tr style={trStyle}>
                                    <td>
                                        <h4>Package Count  : </h4>

                                    </td>
                                    <td>
                                        <FormControl style={{ maxWidth: '50px' }}>
                                            <TextField
                                                InputProps={{ inputProps: { min: 0} }}
                                                name="packCount1"
                                                value={this.state.packCount1}
                                                onChange={this.handleChange}
                                                type="number"
                                                helperText={this.state.packCount1Err}
                                                FormHelperTextProps={{ style: { color: "red" } }}
                                            />
                                        </FormControl>
                                        &nbsp; of &nbsp;
                                        <FormControl style={{ maxWidth: '50px' }}>
                                            <TextField
                                                InputProps={{ inputProps: { min: 0} }}
                                                name="packCount2"
                                                value={this.state.packCount2}
                                                onChange={this.handleChange}
                                                type="number"
                                                helperText={this.state.packCount2Err}
                                                FormHelperTextProps={{ style: { color: "red" } }}

                                            />
                                        </FormControl>
                                    </td>
                                    <td>
                                        <h4>Weight : </h4>
                                    </td>
                                    <td>
                                        <FormControl style={{ maxWidth: '30px' }}>
                                            <TextField
                                                name="weight1"
                                                value={this.state.weight1}
                                                onChange={this.handleChange}
                                            />
                                            <FormHelperText>kg</FormHelperText>
                                        </FormControl>
                                        &nbsp; &nbsp;
                                        <FormControl style={{ maxWidth: '30px' }}>
                                            <TextField
                                                name="weight2"
                                                value={this.state.weight2}
                                                onChange={this.handleChange}
                                            />
                                            <FormHelperText>lbs</FormHelperText>
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr style={trStyle}>
                                    <td>
                                        <h4>From Address : </h4>
                                    </td>
                                    <td colSpan={5}>
                                        <FormControl fullWidth >
                                            <TextField
                                                name="fromAddrs"
                                                multiline
                                                value={this.state.fromAddrs}
                                                rows={3}
                                                onChange={this.handleChange}
                                            />
                                            <FormHelperText>Use 25 chars x 4 lines format</FormHelperText>
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr style={trStyle}>
                                    <td>
                                        <h4>To Address : </h4>
                                    </td>
                                    <td colSpan={5}>
                                        <FormControl fullWidth >
                                            <TextField
                                                multiline
                                                rows={3}
                                                value={this.state.toAddrs}
                                                name="toAddrs"
                                                onChange={this.handleChange} />
                                            <FormHelperText>Use 25 chars x 4 lines format</FormHelperText>
                                        </FormControl>
                                    </td>
                                </tr>
                            </table>
                        </form>
                        <div style={center}>

                            <Button size="large" align="center" onClick={() => this.validateBarcode()} style={{ margin: '20px', textAlign: 'center' }} variant="contained" color="primary">
                                Generate</Button>
                            <Button size="large" onClick={() => this.clearAll()} align="center" style={{ margin: '20px', textAlign: 'center' }} variant="contained" color="primary">
                                Clear</Button>
                        </div>
                    </Card>
                </React.Fragment>
            </React.Fragment>
        );

    }
}
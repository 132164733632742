import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider} from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles'
import NavBar from './navigation/nav-bar/NavBar';
import { SnackbarProvider } from 'notistack';
import './App.css'
import 'typeface-roboto';

const theme = createTheme({
    palette: {
        primary: {
            main: "#d50000",
            light: "#ef5350"
        },
        secondary: {
            main: "#555555",
            light: "#f5f5f5"
        }
    }
});
  

export default function App(){

  return (
    <div className="appContainer">
      <MuiThemeProvider theme={theme}>
      <SnackbarProvider anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                        <Router>
                            <NavBar />
                        </Router>
                    </SnackbarProvider>
    </MuiThemeProvider>
        
    </div>
  )};
